* {
    box-sizing: border-box;

}



body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    background: #65289f;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}


header {
    padding: 20px;
    text-align: center;
    width: 100%;
    background: rgba(0,0,0,0.1);
    height: 68px;
    max-height: 68px;
    position: relative;
}


.social-buttons {
    display: block;
}

.social-buttons a {
    display: inline-block;
    margin: 0 14px 0 0;
    font-size: 1.4em;
    text-shadow: 0px 0px 0px #000;
    opacity: 0.9;
}

.social-buttons a:hover {
    display: inline-block;
    margin: 0 14px 0 0;
    transform: scale(1.2);
    text-shadow: 2px 5px 10px #000;
    opacity: 1;
}



.content-wrapper {
    margin: 0;
    padding: 30px;
}

.centered {
    text-align: center;
}


a {
    color: inherit;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}

section p {
    margin-top: 0;
    margin-bottom: 0;
}

a:hover {
    opacity: 0.7;
}


h1 {
    text-transform: uppercase;
    text-align: center;
    display: none;
}


.main-content h2 {
    text-align: center;
}


.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

p {
    font-size: 15px;
}

@media (min-width: 960px) and (max-width: 1599px) {

    .content-wrapper {
        max-width: 950px;
        margin: 0 auto;
        width: 100%;
    }

    p {
        font-size: 12.5px;
    }
}



.listen-btn {
    font-size: 1.2em;
    font-weight: 700;
    display: block;
}

.listen-btn span {
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
    display: block;
    font-size: 1.5em;
}

.listen-btn:hover span {
    animation: headPhones 3.5s infinite;

}

@keyframes headPhones {
    0%   { transform: rotate(0deg); }
    20%  { transform: rotate(-10deg); }
    40%  { transform: rotate(10deg); }
    60%  { transform: rotate(0deg) }
    70%  { transform: rotate(-10deg) }
    80%  { transform: rotate(0deg) }
    90%  { transform: rotate(10deg) }
    100% { transform: rotate(0deg); }
  }







@media (max-width: 1024px) {

    .social-buttons a {
        display: inline-block;
        margin: 0 10px 0 0;
        font-size: 1.2em;
    }

}

.social-buttons a:last-child {
    margin: 0;
}






.layout-switch, .color-switch {
    background: rgba(0,0,0,0.1);
}

section p {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    line-height: 1.4;
}

img {
    max-width: 100%;


}




.grid-3 .cover-images.grid-2 {
    display: block;
    width: 100%;
    max-width: 100%;
}




.cover-images {
    position: relative;
    margin: 25px auto 30px;
}



.cover-images .image.active {
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 9;
}



.cover-images .cover-front {
    position: relative;
}


.grid-3 .cover-images .cover-back {
    position: absolute;
    top: 0;
    left: 0;

}

.grid-3 .cover-images .image.active.hide {
    opacity: 0;
    z-index: 8;
}

.img-toggle {
    opacity: 0;
}

.grid-3 .img-toggle {
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
    display: block;
    margin-bottom: 40px;
    opacity: 1;
}




@media(min-width: 960px) {
.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1.2fr 1fr;
    grid-gap: 60px;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 80px;
}
}

.media-files {
    padding-top: 30px;
    padding-bottom: 30px;
}

.media-files div {
    text-align: center;
}

.media-files h3 {
    text-align: center;
    text-transform: uppercase;
}

.video-cont {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.5%;
}


.media-files iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 12px solid rgba(0,0,0,0.1);
}


footer {
    padding: 20px;
    text-align: center;
    width: 100%;
    background: rgba(0,0,0,0.1)
}




.main-content.grid-3 h2 {
    display: block;
}

.icon-mailto {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
}



@media(max-width: 959px) {

section.centered + section {
    margin-top: 48px;
}

.listen-btn {
    font-size: 0.9em;
    letter-spacing: 0.03em;
}

.listen-btn span {
    font-size: 2em;
    margin-bottom: 5px;
}

}


/* ICOMOON FONT */



@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?bnawqn');
    src:  url('fonts/icomoon.eot?bnawqn#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?bnawqn') format('truetype'),
      url('fonts/icomoon.woff?bnawqn') format('woff'),
      url('fonts/icomoon.svg?bnawqn#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-mailto:before {
    content: "\e900";
  }
  .icon-headphones:before {
    content: "\e910";
  }
  .icon-music:before {
    content: "\e911";
  }
  .icon-file-music:before {
    content: "\e928";
  }
  .icon-facebook:before {
    content: "\ea90";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
  .icon-youtube:before {
    content: "\ea9d";
  }
